<template>
    <router-view @open-sidebar="openSidebar" @close-sidebar="closeSidebar"></router-view>
</template>

<script>
export default {
    methods: {
        openSidebar() {
            this.$emit('open-sidebar')
        },

        closeSidebar() {
            this.$emit('close-sidebar')
        },
    },

    beforeRouteUpdate(to, from, next) {
        this.closeSidebar()
        next()
    },

    beforeRouteLeave(to, from, next) {
        this.closeSidebar()
        next()
    },
}
</script>